import React, {useEffect} from 'react';

import '../css/WorksNav.scss';

import {gsap}         from 'gsap';
import ScrollToPlugin from 'gsap/ScrollToPlugin';


const WorksNavList = ({datum, anchorScroll, currentWorkId}) => {


	return (
		<li>
			<span className={'dot ' + ((currentWorkId === datum.id) && 'current')} title={datum.title.rendered} onClick={() => {
				anchorScroll(`#work${datum.id}`);
			}}>{datum.title.rendered}</span>
		</li>
	);
};

const WorksNav = ({data, currentWorkId}) => {
	useEffect(() => {
		gsap.registerPlugin(ScrollToPlugin);
	}, []);

	/**
	 * WORKS アンカースクロール
	 * @param anchorId {string}
	 */
	const anchorScroll = (anchorId) => {
		const correction = 5;
		const scrolled = window.scrollY;
		const startPosTop = Math.round(window.innerHeight * 0.325);
		const parent = document.querySelector(anchorId)
			.closest('.pin-spacer');
		const parentPosTop = parent.getBoundingClientRect().top;

		gsap.to(window, {
				duration: 0.8,
				scrollTo: {y: parentPosTop - startPosTop + scrolled + correction, autoKill: true},
				ease: 'power2'
			}
		);
	};

	return (
		<ul className={'works_nav'}>
			{data.map((datum) => {
				return <WorksNavList key={datum.id} datum={datum} anchorScroll={anchorScroll} currentWorkId={currentWorkId} />;
			})}
		</ul>
	);
};

export default WorksNav;