import React from 'react';
import '../css/ContentContainer.scss';

const ContentContainer = ({className, children}) => {
	return (
		<div className="content">
			<div className={'content-inner ' + (className ? className : '')}>
				{children}
			</div>
		</div>
	);
};

export default ContentContainer;