import React from 'react';
import '../css/ScrollIcon.scss';

const ScrollIcon = () => {
	return (
		<div className="scroll_icon">
		</div>
	);
};

export default ScrollIcon;