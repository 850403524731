import React    from 'react';
import {Helmet} from 'react-helmet-async';

const HelmetData = ({pageTitle, logoDisplay}) => {
	const data = {
		title: 'シンセイサクシツ'
	};

	if (pageTitle) {
		data.title += ' ' + pageTitle;
	}

	return (
		<Helmet>
			<title>{data.title}</title>
			<style>
				{logoDisplay === false && `.logo {display:none}`}
			</style>
		</Helmet>
	);
};

export default HelmetData;