import React, {useEffect} from 'react';
import HelmetData         from '../components/HelmetData';
import Anim01             from '../components/Anim01';
import Information        from '../components/Information';

const Home = ({currentPageHandler}) => {
	useEffect(() => {
		currentPageHandler('HOME');
	}, [currentPageHandler]);

	return (
		<>
			<HelmetData pageTitle={''} logoDisplay={false} />
			<Anim01 />
			<Information />
		</>
	);
};

export default Home;