import React, {useCallback, useEffect, useState} from 'react';
import axios                                     from 'axios';

import '../css/PhotosWorks.scss';

import HelmetData       from '../components/HelmetData';
import ContentContainer from '../components/ContentContainer';
import PageTitle        from '../components/PageTitle';
import WorksList        from '../components/WorksList';
import WorksNav         from '../components/WorksNav';
import ScrollIcon       from '../components/ScrollIcon';
import Loading          from '../components/Loading';

// Context
export const WorksContext = React.createContext();

const Works = ({currentPageHandler}) => {
	useEffect(() => {
		currentPageHandler('WORKS');
	}, [currentPageHandler]);

	const [loading, setLoading] = useState(true);
	const [data, setData] = useState([]);
	const [error, setError] = useState(false);
	const [currentWorkId, setCurrentWorkId] = useState('');

	const getWorksData = () => {
		axios.get('https://s4ec.jp/s4ec_wp/wp-json/wp/v2/works?_embed')
			.then((response) => {
				setData(response.data);
				setLoading(false);
			})
			.catch(() => {
				setError(true);
				setLoading(false);
			});
	};

	useEffect(() => {
		getWorksData();
	}, []);

	/**
	 * ステートに現在表示されているWorkのidをセットする
	 * @param current {string}
	 */
	const currentWorkIdHandler = useCallback((current) => {
		setCurrentWorkId(current);
	}, []);

	return (
		<>
			<HelmetData pageTitle={'WORKS'} />
			<PageTitle title={'works'} />
			<ContentContainer>
				{loading ?
				 <Loading /> :
				 error ? null :
				 (data.length > 0) &&
					 <WorksNav data={data} currentWorkId={currentWorkId} />
				}
				<WorksContext.Provider value={currentWorkIdHandler}>
					{loading ? null :
					 error ?
					 <p className="load_error_message">データの読み込みに失敗しました。</p> :
					 (data.length > 0) &&
						 <>
							 <ScrollIcon />
							 <WorksList data={data} />
						 </>
					}
				</WorksContext.Provider>
			</ContentContainer>
		</>
	);
};

export default Works;