import React     from 'react';
import {Link} from 'react-router-dom';
import LogoImage from '../imgs/logo.svg';
import '../css/Logo.scss';

const Logo = () => {
	return (
		<Link to="/" className="logo">
			<img src={LogoImage} alt="シンセイサクシツ WEB & GRAPHICS" />
		</Link>
	);
};

export default Logo;