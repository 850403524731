import {Routes, Route} from 'react-router-dom';

import React, {useEffect, useState} from 'react';
import Nav                          from './components/Nav';
import Home                         from './pages/Home';
import Works                        from './pages/Works';
import Photos                       from './pages/Photos';
import NotFound                     from './pages/NotFound';
import Logo                         from './components/Logo';


function App() {
	const [currentPage, setCurrentPage] = useState('');
	const [globalMenu, setGlobalMenu] = useState(false);

	const currentPageHandler = (page) => {
		setCurrentPage(page);
	};

	const globalMenuHandler = () => {
		setGlobalMenu(!globalMenu);
	};

	useEffect(() => {
		setGlobalMenu(false);
	}, [currentPage]);

	return (
		<div className="App">
			<Logo />
			<Nav globalMenu={globalMenu} globalMenuHandler={globalMenuHandler} />
			<Routes>
				<Route path="/" element={
					<Home currentPageHandler={currentPageHandler} />} />
				<Route path="/works" element={
					<Works currentPageHandler={currentPageHandler} />} />
				<Route path="/photos" element={
					<Photos currentPageHandler={currentPageHandler} />} />
				<Route path="*" element={
					<NotFound currentPageHandler={currentPageHandler} />} />
			</Routes>
		</div>
	);
}

export default App;