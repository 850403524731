import React, {useState, useEffect} from 'react';
import '../css/Information.scss';
import axios                        from 'axios';

const Information = () => {
	const [loading, setLoading] = useState(true);
	const [data, setData] = useState([]);
	const [error, setError] = useState(false);

	const getInformationData = () => {
		axios.get('https://s4ec.jp/s4ec_wp/wp-json/wp/v2/posts?per_page=1')
			.then((response) => {
				setData(response.data);
				setLoading(false);
				if(response.data.length < 1) {
					setLoading(true);
				}
			})
			.catch(() => {
				setError(true);
				setLoading(false);
			});
	};

	useEffect(() => {
		getInformationData();
	}, []);

	return (
		<>
			{loading ? null :
			 <ul className="information">
				 <li>
					 {error ? <>お知らせを読み込みに失敗しました。</> :
						data[0].title.rendered
					 }
				 </li>
			 </ul>
			}
		</>
	);
};

export default Information;