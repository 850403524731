import React, {useEffect} from 'react';
import HelmetData         from '../components/HelmetData';
import ContentContainer   from '../components/ContentContainer';
import PageTitle          from '../components/PageTitle';

const messageStyle = {
	'margin-top': '100px',
	'text-align': 'center'
};

const NotFound = ({currentPageHandler}) => {
	useEffect(() => {
		currentPageHandler('NOT_FOUND');
	}, [currentPageHandler]);

	return (
		<>
			<HelmetData pageTitle={'404 Not Found'} />
			<PageTitle title={'404 Not Found'} />
			<ContentContainer className='o-middle'>
				<p style={messageStyle}>お探しのページは<br />見つかりませんでした。</p>
			</ContentContainer>
		</>
	);
};

export default NotFound;