import React from 'react';
import '../css/Loading.scss';

const Loading = () => {
	return (
		<div className="loading">
			<svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80">
				<circle cx="40" cy="40" r="25" className="circle_bg_light01" />
				<circle cx="40" cy="40" r="25" className="circle_bg_light02" />
				<circle cx="40" cy="40" r="25" className="circle_line" />
			</svg>
		</div>
	);
};

export default Loading;