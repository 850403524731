import React, {useRef, useState} from 'react';
import OnImagesLoaded                        from 'react-on-images-loaded';
import {gsap}                                from 'gsap';

import '../css/WorksList.scss';

import WorkItem from './WorkItem';


/**
 * WorksList Component
 */
const WorksList = ({data}) => {
	const worksListRef = useRef();
	const [imageLoadState, setImageLoadState] = useState(false);
	const urlMouseAnimRef = useRef();

	/**
	 * url text mouseEnter, mouseLeave
	 */
	const onEnter = (e) => {
		urlMouseAnimRef.current = gsap.fromTo(e.target, {
			backgroundImage: 'linear-gradient(90deg, #b3b3b3 0%, #b3b3b3 0%, #808080 0%)'
		}, {
			backgroundImage: 'linear-gradient(90deg, #b3b3b3 0%, #b3b3b3 100%, #808080 100%)',
			duration: 0.3,
			ease: 'Power4.out'
		});
	};

	const onLeave = () => {
		urlMouseAnimRef.current.reverse();
	};

	return (
		<div ref={worksListRef} className="works-wrapper">
			<OnImagesLoaded onLoaded={() => setImageLoadState(true)} classNameOnLoaded="works images-loaded">
				{data.map((datum) => {
					return <WorkItem key={datum.id} datum={datum} imageLoadState={imageLoadState} onEnter={onEnter} onLeave={onLeave} />;
				})}
			</OnImagesLoaded>
		</div>
	);
};

export default WorksList;