import React, {useEffect, useState} from 'react';
import axios                        from 'axios';

import '../css/PhotosWorks.scss';

import HelmetData       from '../components/HelmetData';
import PhotosList       from '../components/PhotosList';
import ContentContainer from '../components/ContentContainer';
import PageTitle        from '../components/PageTitle';
import Loading          from '../components/Loading';


const Photos = ({currentPageHandler}) => {
	useEffect(() => {
		currentPageHandler('PHOTOS');
	}, [currentPageHandler]);

	const [loading, setLoading] = useState(true);
	const [data, setData] = useState([]);
	const [error, setError] = useState(false);

	const getPhotosData = () => {
		let photosData = [];
		let count = 0;
		const maxPages = 2;

		function getData() {
			if (count < maxPages) {
				axios.get('https://s4ec.jp/s4ec_wp/wp-json/wp/v2/picture?_embed&per_page=100&page=' + (count + 1))
					.then((response) => {
						photosData = photosData.concat(response.data);
						count++;
						getData();
					})
					.catch(() => {
						setError(true);
						setLoading(false);
						console.log('データを取得できませんでした。');
					});
			} else {
				setData(photosData);
				setLoading(false);
			}
		}

		getData();
	};

	useEffect(() => {
		getPhotosData();
	}, []);

	return (
		<>
			<HelmetData pageTitle={'PHOTOS'} />
			<PageTitle title={'photos'} />
			<ContentContainer className="o-full_-s">
				{loading ?
				 <Loading /> : error ?
											 <p className="load_error_message">データの読み込みに失敗しました。</p> :
											 (data.length > 0) &&
												 <PhotosList data={data} />
				}
			</ContentContainer>
		</>
	);
};

export default Photos;