import React, {useEffect, useRef, useState} from 'react';
import OnImagesLoaded                       from 'react-on-images-loaded';
import {gsap}                               from 'gsap';
import {ScrollTrigger}                      from 'gsap/ScrollTrigger';
import FsLightbox                           from 'fslightbox-react';

import '../css/PhotosList.scss';

/**
 * Cards
 */
const Cards = ({data, openLightbox}) => {
	let cardHtml = data.map((datum, index) => {
		return (
			<div key={datum.id} id={'photos' + datum.id} className="photo">
				<div className="photo-image" onClick={() => openLightbox(index)}>
					<img src={datum._embedded['wp:featuredmedia'][0].media_details.sizes['thumbnail_top_s'].source_url} alt="" width="360" />
				</div>
			</div>
		);
	});

	return (
		<>
			{cardHtml}
		</>
	);
};

/**
 * PhotosList Component
 */
const PhotosList = ({data}) => {
	const photosListRef = useRef();
	const [imageLoadState, setImageLoadState] = useState(false);
	const [lightboxController, seiLightboxController] = useState({
		toggler: false,
		sourceIndex: 0
	});
	const [fsSources, setFsSources] = useState([]);

	const openLightbox = (sourceIndex) => {
		seiLightboxController({
			toggler: !lightboxController.toggler,
			sourceIndex: sourceIndex
		});
	};

	// fsLightbox
	useEffect(() => {
		if (data) {
			let sources = data.map((datum) => {
				return datum._embedded['wp:featuredmedia'][0].media_details.sizes['full'].source_url;
			});
			setFsSources(sources);
		}
	}, [data]);

	// ScrollTrigger
	useEffect(() => {
		gsap.registerPlugin(ScrollTrigger);
	}, []);

	useEffect(() => {
		let scrollTriggers;

		const photosAnim = () => {
			const gsapSelector = gsap.utils.selector(photosListRef);
			if (gsapSelector('.photo .photo-image').length === 0) return false;
			gsap.set(gsapSelector('.photo .photo-image'), {scale: 2, opacity: 0});
			gsap.set(gsapSelector('.photo'), {autoAlpha: 1});

			return ScrollTrigger.batch(gsapSelector('.photo .photo-image'), {
				onEnter: batch => gsap.to(batch, {scale: 1, opacity: 1, stagger: 0.1, overwrite: true}),
				onLeave: batch => gsap.to(batch, {scale: 2, opacity: 0, overwrite: true}),
				onEnterBack: batch => gsap.to(batch, {scale: 1, opacity: 1, stagger: 0.1, overwrite: true}),
				onLeaveBack: batch => gsap.to(batch, {scale: 2, opacity: 0, overwrite: true}),
			});
		};

		if (imageLoadState) {
			setTimeout(() => {
				scrollTriggers = photosAnim();
			}, 100);
		}

		// Clean Up
		return () => {
			if (scrollTriggers) {
				scrollTriggers.forEach((scrollTrigger) => {
					scrollTrigger.kill();
				});
			}
		};
	}, [imageLoadState]);


	// return Component
	return (
		<div ref={photosListRef} className="photos-wrapper">
			<OnImagesLoaded onLoaded={() => setImageLoadState(true)} classNameOnMount="images-loading" classNameOnLoaded="photos images-loaded">
				<Cards data={data} openLightbox={openLightbox} />
			</OnImagesLoaded>
			<FsLightbox
				toggler={lightboxController.toggler}
				sources={fsSources}
				type="image"
				sourceIndex={lightboxController.sourceIndex}
			/>
		</div>
	);
};

export default PhotosList;
