import {Link} from 'react-router-dom';
import '../css/Nav.scss';

const Nav = ({globalMenu, globalMenuHandler}) => {
	return (
		<div className={globalMenu ? 'menu_on' : ''}>
			<nav className="global_nav">
				<ul>
					<li>
						<Link to="/">HOME</Link>
					</li>
					<li>
						<Link to="/works">WORKS</Link>
					</li>
					<li>
						<Link to="/photos">PHOTOS</Link>
					</li>
				</ul>
			</nav>
			<div className="menu_icon" onClick={globalMenuHandler}>
			</div>
		</div>
	);
};

export default Nav;