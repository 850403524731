import React, {useContext, useEffect, useRef} from 'react';

import '../css/WorksNav.scss';

import {gsap}          from 'gsap';
import {ScrollTrigger} from 'gsap/ScrollTrigger';

import {WorksContext} from '../pages/Works';


/**
 * Work
 */
const WorkItem = ({datum, imageLoadState, onEnter, onLeave}) => {
	const workRef = useRef();
	const tlRef = useRef();

	// TODO 2023/03/17 currentWorkIdHandler関数が、useEffectに渡していないと警告が出るのを修正。（渡すと挙動がおかしくなる）
	// WORK ITEM が、表示されたら current id をセットする。
	// Context　currentWorkIdHandler 取得
	const currentWorkIdHandler = useContext(WorksContext);

	// ScrollTrigger
	useEffect(() => {
		gsap.registerPlugin(ScrollTrigger);
	}, []);

	useEffect(() => {
		if (!imageLoadState) return;

		let scrollTriggerObj;

		const workAnim = () => {
			const gsapSelector = gsap.utils.selector(workRef);

			ScrollTrigger.matchMedia({
				'(max-width:800px)': () => {
					let animProps = {
						startImageWidth: '60%',
						endImageWidth: '100%'
					};

					tlRef.current = gsap.timeline()
						.to(gsapSelector('.work-inner'), {opacity: 1})
						.fromTo(gsapSelector('.work-image'),
							{width: animProps.startImageWidth},
							{width: animProps.endImageWidth}, '<')
						.to(gsapSelector('.work-image img'), {filter: 'grayscale(0)'}, '<')
						.to(gsapSelector('.work-text_content'),
							{clipPath: 'inset(0% 0% 0% 0%)'}, '-=0.3');

					let sctProps = {
						animation: tlRef.current,
						trigger: workRef.current,
						start: 'top 20%',
						end: `${workRef.current.offsetHeight} 20%`,
						// markers:true,
						pin: true,
						toggleActions: 'restart reverse restart reverse'
					};

					scrollTriggerObj = ScrollTrigger.create(sctProps);
				},

				'(min-width:801px)': () => {
					let animProps = {
						startImageWidth: '25%',
						endImageWidth: '55%',
						startTextContentWidth: 'calc(25% - 30px)',
						endTextContentWidth: 'calc(45% - 30px)',
					};

					tlRef.current = gsap.timeline()
						.to(gsapSelector('.work-inner'), {opacity: 1})
						.fromTo(gsapSelector('.work-image'),
							{width: animProps.startImageWidth},
							{width: animProps.endImageWidth}, '<')
						.to(gsapSelector('.work-image img'), {transform: 'rotateY(15deg)', filter: 'grayscale(0)'}, '<')
						.fromTo(gsapSelector('.work-text_content'),
							{width: animProps.startTextContentWidth},
							{width: animProps.endTextContentWidth, clipPath: 'inset(0% 0% 0% 0%)'}, '<');

					let sctProps = {
						animation: tlRef.current,
						trigger: workRef.current,
						start: 'top 32.5%',
						end: `${workRef.current.offsetHeight} 32.5%`,
						// markers: true,
						pin: true,
						// toggleActions: 'restart reverse restart reverse',
						onEnter: () => {
							tlRef.current.restart();
							currentWorkIdHandler(datum.id);
						},
						onLeave: () => {
							tlRef.current.reverse();
							currentWorkIdHandler('');
						},
						onEnterBack: () => {
							tlRef.current.restart();
							currentWorkIdHandler(datum.id);
						},
						onLeaveBack: () => {
							tlRef.current.reverse();
							currentWorkIdHandler('');
						}
					};

					scrollTriggerObj = ScrollTrigger.create(sctProps);
				}
			});
		};

		// setTimeout(() => {
		// 	workAnim();
		// }, 100);
		workAnim();

		return () => {
			scrollTriggerObj.kill();
		};
	}, [currentWorkIdHandler, imageLoadState, datum.id]);


	return (
		<div ref={workRef} id={'work' + datum.id} className="work">
			<div className="work-inner">
				<div className="work-image">
					<figure>
						<img src={datum._embedded['wp:featuredmedia'][0].media_details.sizes['portfolio_index_thumb-x2'].source_url} alt="" width="100" />
					</figure>
				</div>
				<div className="work-text_content">
					<div className="inner">
						<h2 className="work-title">{datum.title.rendered}</h2>
						{datum.acf.works_link_url &&
							<div className="work-url">
								<a href={datum.acf.works_link_url} target="_blank" rel="noreferrer noopener" onMouseEnter={onEnter} onMouseLeave={onLeave}>{datum.acf.works_link_url}</a>
							</div>}
					</div>
				</div>
			</div>
		</div>
	);
};

export default WorkItem;