import React, {useCallback, useEffect, useRef, useState} from 'react';
import {gsap}                                            from 'gsap';
import '../css/Anim01.scss';

const logoMaxWidth = 1000;
/**
 * 関数 ロゴリサイズ
 * @param types {object}
 */
const resizeLogoScale = (types) => {
	let windowWidth = window.innerWidth;
	if (windowWidth < logoMaxWidth) {
		let targetWidth = windowWidth * 0.9;
		let scale = (targetWidth / logoMaxWidth).toPrecision(2);
		types[0].style.transform = 'scale(' + scale + ')';
	} else {
		types[0].style.transform = 'scale(1)';
	}

	return false;
};


/**
 * 関数 リサイズ時 最後のタイミングに一回だけ実行
 */
const debounce = (fn, time = 100) => {
	let timerId;
	return (e) => {
		if (timerId) clearTimeout(timerId);
		timerId = setTimeout(fn, time, e);
	};
};


/**
 * トップメインアニメーション 01
 */
const Anim01 = () => {
	const [animStart, setAnimStart] = useState(false);
	const mvRef = useRef();
	const tl01 = useRef();
	const tl02 = useRef();

	/**
	 * 関数 背景グラデーション アニメーション
	 */
	const bgGrdAnim = useCallback((tl02, mainVisual) => {
		tl02.current = gsap.timeline({
			defaults: {duration: 5},
			repeat: -1
		})
			.to(mainVisual, {backgroundImage: 'linear-gradient(to right bottom, #fccd21, #ff71a8)'})
			.to(mainVisual, {backgroundImage: 'linear-gradient(to right bottom, #b2e72a, #29b3e2)'})
			.to(mainVisual, {backgroundImage: 'linear-gradient(to right bottom, #bf77d2, #3477ae)'});
	}, []);

	/**
	 * 関数 タイプアニメーション
	 */
	const typesAnim = useCallback((tl01, types, xLines, yLines, bullets, shadow) => {
		const endY = gsap.utils.wrap([-400, 400]);

		tl01.current = gsap.timeline({
			defaults: {duration: 1.5},
			repeat: 0,
			// paused: true,
			onComplete: () => {
				types[0].classList.remove('pointer');
				gsap.delayedCall(2, () => {
					tl01.current.restart();
				});
			}
		})
			.from(yLines, {
				y: endY,
				rotate: 90,
				rotateY: -180,
				opacity: 0,
				filter: 'blur(2px)',
				stagger: 0.1,
				ease: 'back.out(3)',
			})
			.from(xLines, {
				y: -300,
				rotateX: -180,
				opacity: 0,
				filter: 'blur(2px)',
				rotate: 90,
				stagger: 0.2,
				ease: 'back.out(3)'
			}, '<')
			.from(bullets, {
				x: -300,
				z: 120,
				rotateX: -180,
				opacity: 0,
				filter: 'blur(2px)',
				stagger: 0.15,
				duration: 1,
				ease: 'back.out(1)'
			})
			.from(shadow, {
					skewX: '0deg',
					scaleY: 1,
					opacity: 0,
					ease: 'Power4.out',
					onComplete: () => {
						types[0].classList.add('pointer');
					}
				},
				'+=1')
			.addPause()
			.to(shadow, {
				opacity: 0,
				duration: 0.01
			})
			.to([xLines, yLines, bullets], {
				x: 'random(-600, 600, 10)',
				y: 'random(-600, 600, 10)',
				z: 'random(-120, 120, 10)',
				rotate: 'random(-180, 180)',
				opacity: 0,
				filter: 'blur(5px)',
				duration: 0.4
			}, '<');
	}, []);

	// タイプアニメーション再生
	const typeAnimPlay = () => {
		tl01.current.play();
	};

	/**
	 * useEffect
	 */
	useEffect(() => {
		if (!animStart) setAnimStart(true);

		const mainAnim = () => {
			const gsapSelector = gsap.utils.selector(mvRef);

			let mainVisual = mvRef.current;
			let types = gsapSelector('.main-visual-inner');
			let xLines = gsapSelector('.x_line');
			let yLines = gsapSelector('.y_line');
			let bullets = gsapSelector('.bullet');
			let shadow = gsapSelector('.types-shadow img');
			xLines.reverse();
			bullets.reverse();

			// アニメーション関数実行
			gsap.set(types[0], {autoAlpha: 1});
			bgGrdAnim(tl02, mainVisual);
			typesAnim(tl01, types, xLines, yLines, bullets, shadow);

			// ロゴリサイズ
			window.addEventListener('resize', () => debounce(resizeLogoScale(types)));
			resizeLogoScale(types);
		};

		if (animStart) mainAnim();

		let tl01Anim = tl01.current;
		let tl02Anim = tl02.current;

		return () => {
			if (tl01Anim) tl01Anim.kill();
			if (tl02Anim) tl02Anim.kill();
		};
	}, [animStart, bgGrdAnim, typesAnim]);

	return (
		<div ref={mvRef} id="main-visual" className="main-visual">
			<div className="main-visual-inner">
				<div className="types-shadow">
					<img src={`${process.env.PUBLIC_URL}/imgs/anim01/logo-shadow.svg`} width="1000" height="200 " alt="" />
				</div>
				<div className="types" onClick={typeAnimPlay}>
					{/* シ */}
					<div id="shi01" className="type type-shi01">
						<div className="tp01 bullet">
							<img src={`${process.env.PUBLIC_URL}/imgs/anim01/logo-bullet.svg`} width="35" height="26" alt="" />
						</div>
						<div className="tp02 bullet">
							<img src={`${process.env.PUBLIC_URL}/imgs/anim01/logo-bullet.svg`} width="35" height="26" alt="" />
						</div>
						<div className="tp03 y_line">
							<img src={`${process.env.PUBLIC_URL}/imgs/anim01/logo-line-y-shi-n-i-ku-tsu.svg`} width="22" height="174" alt="" />
						</div>
					</div>
					{/* ン */}
					<div id="n" className="type type-n">
						<div className="tp01 bullet">
							<img src={`${process.env.PUBLIC_URL}/imgs/anim01/logo-bullet.svg`} width="35" height="26" alt="" />
						</div>
						<div className="tp02 y_line">
							<img src={`${process.env.PUBLIC_URL}/imgs/anim01/logo-line-y-shi-n-i-ku-tsu.svg`} width="22" height="174" alt="" />
						</div>
					</div>
					{/* セ */}
					<div id="se" className="type type-se">
						<div className="tp01 x_line">
							<img src={`${process.env.PUBLIC_URL}/imgs/anim01/logo-line-x-se-ku.svg`} width="89" height="22" alt="" />
						</div>
						<div className="tp02 y_line">
							<img src={`${process.env.PUBLIC_URL}/imgs/anim01/logo-line-y-se-sa.svg`} width="22" height="200" alt="" />
						</div>
						<div className="tp03 x_line">
							<img src={`${process.env.PUBLIC_URL}/imgs/anim01/logo-line-x-se-bottom.svg`} width="56" height="22" alt="" />
						</div>
					</div>
					{/* イ */}
					<div id="i" className="type type-i">
						<div className="tp01 x_line">
							<img src={`${process.env.PUBLIC_URL}/imgs/anim01/logo-line-x-i-up.svg`} width="97" height="22" alt="" />
						</div>
						<div className="tp02 y_line">
							<img src={`${process.env.PUBLIC_URL}/imgs/anim01/logo-line-y-shi-n-i-ku-tsu.svg`} width="22" height="174" alt="" />
						</div>
					</div>
					{/* サ */}
					<div id="sa" className="type type-sa">
						<div className="tp01 x_line">
							<img src={`${process.env.PUBLIC_URL}/imgs/anim01/logo-line-x-sa-up.svg`} width="111" height="22" alt="" />
						</div>
						<div className="tp02 y_line">
							<img src={`${process.env.PUBLIC_URL}/imgs/anim01/logo-line-y-sa-left.svg`} width="22" height="45" alt="" />
						</div>
						<div className="tp03 y_line">
							<img src={`${process.env.PUBLIC_URL}/imgs/anim01/logo-line-y-se-sa.svg`} width="22" height="200" alt="" />
						</div>
					</div>
					{/* ク */}
					<div id="ku" className="type type-ku">
						<div className="tp01 y_line">
							<img src={`${process.env.PUBLIC_URL}/imgs/anim01/logo-line-y-ku-left.svg`} width="22" height="45" alt="" />
						</div>
						<div className="tp02 x_line">
							<img src={`${process.env.PUBLIC_URL}/imgs/anim01/logo-line-x-se-ku.svg`} width="89" height="22" alt="" />
						</div>
						<div className="tp03 y_line">
							<img src={`${process.env.PUBLIC_URL}/imgs/anim01/logo-line-y-shi-n-i-ku-tsu.svg`} width="22" height="174" alt="" />
						</div>
					</div>
					{/* シ */}
					<div id="shi02" className="type type-shi02">
						<div className="tp01 bullet">
							<img src={`${process.env.PUBLIC_URL}/imgs/anim01/logo-bullet.svg`} width="35" height="26" alt="" />
						</div>
						<div className="tp02 bullet">
							<img src={`${process.env.PUBLIC_URL}/imgs/anim01/logo-bullet.svg`} width="35" height="26" alt="" />
						</div>
						<div className="tp03 y_line">
							<img src={`${process.env.PUBLIC_URL}/imgs/anim01/logo-line-y-shi-n-i-ku-tsu.svg`} width="22" height="174" alt="" />
						</div>
					</div>
					{/* ツ */}
					<div id="tsu" className="type type-tsu">
						<div className="tp01 bullet">
							<img src={`${process.env.PUBLIC_URL}/imgs/anim01/logo-bullet.svg`} width="35" height="26" alt="" />
						</div>
						<div className="tp02 bullet">
							<img src={`${process.env.PUBLIC_URL}/imgs/anim01/logo-bullet.svg`} width="35" height="26" alt="" />
						</div>
						<div className="tp03 y_line">
							<img src={`${process.env.PUBLIC_URL}/imgs/anim01/logo-line-y-shi-n-i-ku-tsu.svg`} width="22" height="174" alt="" />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Anim01;